.qa_btn {
  width: 8vw;
  height: 7vh;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.codemaina_btns {
  margin-top: 2rem;
  display: flex;
  gap: 20px;
  margin-left: 2rem;
}

.list_btns {
  width: 10vw;
  height: 5vh;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.q_a_inputs {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2rem;
}

.q_a_inputs input {
  width: 40vw;
  height: 6vh;
  margin-top: 8px;
}

.q_a_input{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-left: 2rem;

}
.q_a_input input{
    width: 40vw;
    height: 25vh;
    margin-top: 8px;

}
.doitbeteer_inputs{
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2rem;
}
.doitbeteer_inputs input{
  width: 25vw;
  height: 50vh;
  margin-top: 8px;
}
.doitbeteer_description{
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-left: 2rem;

}
.doitbeteer_description input{
  width: 25vw;
  height: 5vh;
  margin-top: 8px;

}

.code_mania_submit_btn{
    width: 10vw;
    height: 5vh;
    background-color: #033fc1;
    color: white;
    border: none;
    margin-top: 2rem;
    margin-left: 2rem;
    border-radius: 5px;
    cursor: pointer;
}


.host_table {
  width: 100%;
  border-collapse: collapse;
  border: 20px;
  margin-top: 1rem;
}


tr:nth-child(odd) {
  background-color: #f7f6fe;
}

th {
  font-size: 12px;
  padding: 5px;
  position: sticky;
  top: 0;
  background-color: white;
}

td {
  border-bottom: 1px solid lightgrey;
  font-size: 13px;
  text-align: center;
  padding: 6px;
  cursor: pointer;
  overflow-y: auto;
  max-height: 500px;
}



.polls_input{
  width: 30vw;
  height: 15vh;
  margin-top: 8px;
}

.all_inputs input{
  width: 22vw;
  height: 4.5vh;
  margin-top: 1rem;
  margin-left: 2rem;

}

.all_inputs_Quiz input{
  height: 4.5vh;
  margin-top: 1rem;
}

.option-input {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0.5rem;
}

.radio-btn {
  margin-right: 0.5rem;
  width: 10vw;
}

.option-input-field {
  flex: 1;
  padding: 0.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.component_btn{
  width: 10vw;
  height: 5vh;
  background-color: #033fc1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}

.path_styLE{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  /* margin-top: 2rem; */
}