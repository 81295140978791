.profile_container {
  position: relative;
}

.pop_container {
  position: absolute;
  width: auto;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  border-radius: 5px;
  right: 20px;
  background-color: var(--color-darkblue);
  text-align: center;
  border: none;
  outline: none;
  z-index: 2;
}

.pop_container p {
  margin: 10px;
}

.dotted_line {
  border-top: 1px dashed rgb(255, 255, 255);
  margin-left: 5px;
  margin-right: 5px;
}

.popup_text {
  padding: 5px;
}

.popup_text p {
  font-size: var(--fontsize-small);
  font-family: var(--fontfamily-serif);
  cursor: pointer;
}

.logout_heading {
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  padding: 5px;
  border-bottom: 1px solid white;
}
