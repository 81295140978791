.form__heading {
  font-family: var(--fontfamily-serif);
  font-size: var(--fontsize-large);
  text-align: center;
  margin: 5px 0;
  text-decoration: underline;
}

.input-style {
  height: 6vh;
}

/* .form__data input{ */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.product_form_container {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.featured_style {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-bold);
}
