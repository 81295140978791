.sidebar__container {
  background-color: var(--color-darkblue);
  color: var(--color-white);
  width: 18vw;
  height: 100vh;
  padding: 1vw 0;
  overflow: auto;
}

.sidebar__container::-webkit-scrollbar {
  display: none;
}

.kickr__logo {
  width: 15vw;
}

.sidebar__data {
  margin: 0.5vh 0;
}

.sidebar_item_row {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 1vw;
}

.sidebar__icon {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  font-size: var(--fontsize-large);
  display: flex;
}

.sidebar__head {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  font-size: var(--fontsize-medium);
  padding-left: 1vw;
}

.sidebar__submenu {
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-regular);
  font-size: var(--fontsize-small);
  padding-left: calc(25px + 1vw);
  margin: 0.5vh 0;
}

.sidebar__submenu__item {
  cursor: pointer;
  padding: 0.5vh 0;
}

.arrow__icon {
  width: 25px;
  height: auto;
}
