.dashboardCard {
  background-color: transparent;
  width: 16rem;
  height: 10rem;
  perspective: 1000px;
  position: relative;
  z-index: 1;
  margin: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-weight: 500;
}

.dashboardCard__detailsWrapper {
  display: flex;
  height: 100%;
}

.dashboardCard__details,
.dashboardCard__iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.dashboardCard__title {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.dashboardCard__desc {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-left: -10px;
}

.dashboardCard__iconWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-wrap: break-word;
  height: 100%;
}

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
