.middle_container_top_alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headings {
  width: 40vw;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
}

.old__data {
  width: 30vw;
  height: auto;
  text-align: center;
  padding: 10px;
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
}

.suspend_warning_action {
  padding: 5px;
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  border-radius: 0%;
}

.suspension__letter {
  text-align: center;
  font-family: var(--fontfamily-sansserif);
}
