.middle_container {
  width: 75vw;
  height: auto;
  background-color: #eaeaea80;
  margin-left: 2vw;
  margin-bottom: 10px;
  margin-top: 4vh;
  border-radius: 10px;
}

.path__header {
  margin-top: 3vh;
  color: #033FC1;
}

.client_field {
  display: flex;
  align-items: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  /* margin-top: 20px; */
  padding: 2rem 0;
}

.client_field label {
  font-size: var(--fontsize-medium);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-bold);
}
