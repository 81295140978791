.navbar__container {
  width: 82vw;
  height: 10vh;
  background-color: var(--color-mediumblue);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  outline: none;
}

.profile {
  width: 50px;
  height: auto;
  margin-right: 20px;
  cursor: pointer;
}

.navbar_collapse_align {
  display: flex;
  align-items: center;
}

.burger_menu {
  margin-left: 10px;
  width: 30px;
  height: auto;
  color: var(--color-white);
  cursor: pointer;
}
