.myCard {
  background-color: transparent;
  width: 22rem;
  height: 13rem;
  perspective: 1000px;
  position: relative;
  z-index: 1;
  margin: 0.8rem;
}

.myCard__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.myCard:hover .myCard__wrapper {
  /* transform: rotateY(180deg); */
}

.myCard__front {
  position: relative;
}

.myCard__front,
.myCard__back {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-weight: 700;
}

.myCard__front,
.myCard__front::before,
.myCard__back::before {
  /* background: linear-gradient(
    43deg,
    rgb(65, 88, 208) 0%,
    rgb(200, 80, 192) 46%,
    rgb(255, 204, 112) 100%
  ); */
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
}
/* 
.myCard__back,
.myCard__back::before {
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
} */

.myCard__back {
  transform: rotateY(180deg);
}

.myCard__front::before,
.myCard__back::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 95%;
  height: 95%;
  position: absolute;
  z-index: -1;
  border-radius: 0.5rem;
  filter: blur(1rem);
  animation: animate 5s linear infinite;
}

.myCard__frontImage,
.myCard__frontWrapper {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: inherit;
}

.myCard__frontImage {
  filter: blur(0.15rem);
  -webkit-filter: blur(0.15rem);
}

.myCard__frontWrapper {
  background-color: rgba(0, 0, 0, 0.45);
  /* opacity: 0.5; */
  z-index: 2;
}

.myCard__backImage {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.myCard__actions {
  position: absolute;
  width: 22rem;
  height: auto;
  padding: 0.3rem;
  /* background-color: red; */
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.45);
}

.myCard__imageIcon,
.myCard__editIcon,
.myCard__deleteIcon {
  font-size: 2rem;
  cursor: pointer;
  /* border: 1px solid black; */
  /* border-radius: 100%; */
  padding: 0.2rem;
  /* background-color: red; */
  color: #f1f1f1;
  margin-right: 0.5rem;
}

.myCard__imageIcon {
  margin-right: auto;
  margin-left: 0.5rem;
}

.myCard__editIcon {
  /* margin-right: 0.5rem; */
  /* background-color: green; */
}

.myCard__downloadIcon {
  font-size: 3rem;
  cursor: pointer;
  color: #f1f1f1;
}

.myCard__detailsWrapper {
  display: flex;
  height: 100%;
}

.myCard__details,
.myCard__iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  /* background-color: yellow; */
  height: 100%;
}

.myCard__title {
  font-size: 2rem;
  font-weight: 600;
}

.myCard__desc {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  margin-left: -10px;
}

.myCard__iconWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 30%;
  word-wrap: break-word;
  height: 100%;
}

@keyframes animate {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
