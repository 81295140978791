.breadcrumb__container {
  display: flex;
}

.breadcrumb__path {
  font-size: var(--fontsize-regular);
}

.pathname {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
}
