.otp__button {
  /* width: 6vw;
  height: 6vh; */
  background-color: var(--color-darkblue);
  color: var(--color-white);
  padding-top: 0.8rem;
  border-radius: 5px;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  cursor: pointer;
}

.confirm__password {
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-medium);
  /* margin-left: -8vw; */
}

.new__password {
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-medium);
  /* margin-left: -9.8vw; */
}
