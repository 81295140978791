/* Add these styles to style.css */

/* Style for error message */
.error-message {
  color: var(--color-red);
  background-color: var(--color-blue);
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-bold);
  font-size: var(--fontsize-xsmall);
  margin-right: 5vw;
  margin-top: -8px;
}
