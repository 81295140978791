.otp__button {
  background-color: var(--color-darkblue);
  color: var(--color-white);
  padding: 0.2rem;
  border-radius: 5px;
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  cursor: pointer;
}

.enter__otp {
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-medium);
  /* margin-left: -12vw; */
}

.enter__mail {
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-medium);
  /* margin-left: -9.5vw; */
}
