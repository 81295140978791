.textarea_style {
  width: 20rem;
  height: 6vh;
  display: block;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: var(--fontsize-xsmall);
  color: #555;
  outline: none;
  padding-left: 5px;
}

.textarea_style:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
