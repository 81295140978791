.layout_row {
  display: flex;
}

.layout_childrens {
  width: calc(100vw - 20vw);
  height: calc(100vh - 10vh);
  overflow: scroll;
}

.layout_childrens::-webkit-scrollbar {
  display: none;
}
