.page_container {
  height: 100vh;
  width: 100vw;
  background-image: url(../../../src/assets/images/login_img.jpg);
  display: flex;
  align-items: center;
}

.login_container {
  width: 30vw;
  height: auto;
  margin-left: 5vw;
  border-radius: 10px;
  padding-top: 5vh;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.367);
  background-color: rgba(255, 255, 255, 0.099);
}

.login_container h3 {
  color: var(--color-white);
  font-family: var(--fontfamily-serif);
  font-size: var(--fontsize-large);
  align-items: center;
}

.login_container p {
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-small);
  font-weight: var(--fontweight-light);
  margin-bottom: 5vh;
}

.login_user {
  color: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-medium);
  /* margin-left: -13vw; */
}

.password__style {
  cursor: pointer;
}
