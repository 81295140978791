.table_data {
  overflow-x: scroll;
}

.eye_icon {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.description_heading {
  font-family: var(--fontfamily-serif);
}

.messsage__para {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xsmall);
}
