.input{
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
}

.input input{
  width: 30vw;
  height: 10vh;
  margin-top: 1rem;

}
.input1 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
}

.input1 input {
  width: 20vw;
  height: 5vh;
  margin-top: 1rem;
}

.input2 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
}

.input2 input {
  width: 30vw;
  height: 10vh;
  margin-top: 1rem;
}

.input3 {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 20vw;
  height: 10vh;
  margin-left: 1rem;
}

.input3 input {
  width: 20vw;
  height: 4vh;
  margin-top: 1rem;
}

.submit_butn{
  width: 8vw;
  height: 5vh;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #033fc1;
  color: white;
  margin-top: 1rem;
  margin-left: 1rem;
}


.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38vw;
}

.input-wrapper input {
  flex: 1;
  margin-right: 10px;
}

.input-wrapper button {
  padding: 10px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}

.preview_code{
  width: 30vw;
  height: 50vh;
  border: 1px solid black;
  margin-top: 3rem;
}