.path_styling {
  margin-left: 20px;
 

}

.message__hover {
  word-wrap: break-word;
  white-space: nowrap;
  height: 20px;
  width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message__hover:hover {
  cursor: pointer;
}
