.modal__backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal {
  width: auto;
  height: auto;
  box-shadow: 0 5px 16px rgba(255, 255, 255, 0.2);
  background: var(--color-white);
  color: #000;
  /* position: relative; */
  border-radius: 10px;
  padding: 10px;
}

.close_icon {
  width: 20px;
  height: auto;
  text-align: top, right !important;
  color: var(--color-red);
}
