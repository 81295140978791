.popup__box {
  padding: 10px;
}

.textinput {
  width: 20rem;
  height: 6vh;
  display: block;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: var(--fontsize-xsmall);
  color: #555;
  outline: none;
  padding-left: 5px;
}

.textinput:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.label_styling {
  color: var(--color-black);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-bold);
  /* margin-left: 15px; */
}

.error_styling {
  font-size: var(--fontsize-xsmall) !important;
  font-weight: var(--fontweight-bold) !important;
  font-family: var(--fontfamily-sansserif);
  color: red !important;
  margin-bottom: 0 !important;
  /* margin-left: 20px; */
}
