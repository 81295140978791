.form {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
}

.form__backdrop {
  width: 80%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.form__container {
  /* width: 30%; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
}
