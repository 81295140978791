.table_data {
  overflow: scroll;
}

.table_data {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table_data td,
.table_data th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table_data tr:nth-child(even) {
  background-color: #f8f8f8;
}

.table_data::-webkit-scrollbar {
  display: none;
}

.product_table,
.product_table_row,
.product_table_head,
.product_table_data {
  border: 1px solid gray;
  border-collapse: collapse;
  /* padding: 7px; */
}

.status_action {
  padding: 5px;
  font-size: var(--fontsize-xsmall);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-medium);
  border-radius: 0%;
}
