

.breadcrumb__styling {
  display: flex;
}

.back__icon {
  width: 30px;
  height: auto;
  cursor: pointer;
  margin-right: 5px;
  transition: width 1s;
}

.back__icon:hover {
  width: 35px;
}

.path__header {
  font-family: var(--fontfamily-sansserif);
  font-size: var(--fontsize-xlarge);
}

.dashboard_card {
  display: flex;
  justify-content: space-around;
}

.chart-heading {
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateRangeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.chart-design {
  margin-top: 1rem;
}
