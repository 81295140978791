.main_pop_container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.content h3 {
  font-family: var(--fontfamily-serif);
}

.content {
  width: 30vw;
  height: auto;
  box-shadow: 0 5px 16px rgba(255, 255, 255, 0.2);
  background: var(--color-white);
  font-family: var(--fontfamily-sansserif);
  color: #000;
  position: relative;
  border-radius: 10px;
  padding: 10px;
}

.para_styling h3 {
  margin-bottom: 10px;
  font-size: var(--fontsize-large);
}

.para_styling {
  padding: 10px;
  gap: 10px;
  font-size: var(--fontsize-regular);
}

.button {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  padding: 15px;
  gap: 20px;
  color: var(--color-mediumblue);
  cursor: pointer;
  font-size: var(--fontsize-medium);
  font-family: var(--fontfamily-sansserif);
  font-weight: var(--fontweight-black);
}
